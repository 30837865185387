.survey-heding {
  font-size: 21px;
  color: #09819a;
  font-weight: 600;
}

.survey-submit {
  background-color: #09819a !important;
  border-color: #09819a !important;
  margin-bottom: 20px !important;
}

.btn-right {
  float: right;
}

.card-ui {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
}

.mid-text-color {
  color: #09819a;
}

.options-margin {
  line-height: 25px;
}

.logo-styling {
  text-align: center;
  margin-bottom: 3.4rem;
}

.highlight-div {
  background: #f7e5e7 !important;
  color: black !important;
  border: 1px solid red !important;
}

.questions-ui {
  text-transform: uppercase;
}

.custom-col {
  display: flex;
}

.meal-feedback-container {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-device-width: 300px) and (max-device-width: 630px) {
    width: 85%;
  }
  @media only screen and (min-device-width: 630px) and (max-device-width: 1230px) {
    width: 65%;
  }
}

.col-width {
  @media only screen and (min-device-width: 300px) and (max-device-width: 930px) {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
    margin-bottom: 15px;
  }
}

.col-padding {
  @media only screen and (min-device-width: 100px) and (max-device-width: 480px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.col-width-second {
  @media only screen and (min-device-width: 300px) and (max-device-width: 930px) {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
    margin-bottom: 15px;
    margin-top: 10px;
  }
}

.font-blue {
  color: #09819a;
}

.meal-label-alignment {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
}

.meal-label-alignment {
  svg {
    margin-right: 5px;
    margin-top: 10rem;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 30px;
  }
  ol,
  ul,
  dl {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.divider {
  flex-grow: 1;
  height: 0.1rem;
  background-color: #b8b8b8;
}

.divider_text {
  color: #09819a;
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px;
}

.divider_container {
  margin-top: 8px;
  float: right;
  display: flex;
  align-items: center;

  @media only screen and (min-device-width: 700px) and (max-device-width: 900px) {
    width: 118%;
  }

  @media only screen and (min-device-width: 500px) and (max-device-width: 700px) {
    width: 122.5%;
  }
}

.SigPad {
  margin: auto;
  border: 2px solid #b1babf;
  width: 300px;
  height: 150px;
}

.clear-signature {
  background-color: #09819a !important;
  border-color: #09819a !important;
  border-radius: 0 !important;
  padding: 0.2rem 0.5rem !important;
}

